const _paq = (window._paq = window._paq || [])

/* tracker methods like "setCustomDimension" should be called before "trackPageView" */

_paq.push(['setDocumentTitle', document.domain + '/' + document.title])

_paq.push(['setCookieDomain', '*.mike-cloud.com'])

_paq.push(['setDomains', ['*.mike-cloud.com']])

_paq.push(['trackPageView'])

_paq.push(['enableLinkTracking'])

export function setupMatomo() {
    const u = 'https://dhigroup.matomo.cloud/'

    _paq.push(['setTrackerUrl', u + 'matomo.php'])

    _paq.push(['setSiteId', '8'])

    const d = document,
        g = d.createElement('script')

    g.async = true
    g.src = '//cdn.matomo.cloud/dhigroup.matomo.cloud/matomo.js'
    document.head.appendChild(g)
}

