import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'
import SearchInput from '../SearchInput'
import { defineMessages, useIntl } from 'react-intl'
import RefreshButton from '../buttons/RefreshButton'
import DeleteButton from '../buttons/DeleteButton'
import DataTable from '../DataTable'
import IconButton from '../buttons/IconButton'
import { MikeSlidingPanel } from '@mike/mike-shared-frontend'
import MikeTooltip from '@mike/mike-shared-frontend/mike-tooltip/MikeTooltip'
import {
    deleteUsers,
    inviteUser,
    loadUsers,
    updateUser
} from '../../actions/users'

import { setUsersRowsPerPage } from '../../reducers/users'
import LongName from '../DataTable/renders/LongName'
import Renders from '../DataTable/renders'
import messages from '../../shared/messages'
import { css } from 'emotion'
import ConfirmationDialog from '../Dialog/ConfirmationDialog'
import LongText from '../DataTable/renders/LongText'
import UserInviteForm, {
    UserInviteType
} from '../UserInviteForm/UserInviteForm'
import UserEditForm from '../UserEditForm'
import UsersNav from '../UsersNav'

import { ReactComponent as UserOwner } from '@mike/mike-shared-frontend/media/icons/UserOwner'
import { ReactComponent as UserContributor } from '@mike/mike-shared-frontend/media/icons/UserContributor'
import { ReactComponent as UserReader } from '@mike/mike-shared-frontend/media/icons/UserReader'
import ITenantUser from '../../model/ITenantUser'
import { useTypedSelector } from '../../reducers'

const localMessages = defineMessages({
    title: {
        id: 'screens.users.title'
    },
    confirmationDialogTitle: {
        id: 'screens.users.confirmationDialog.title'
    },
    confirmationDialogMessage: {
        id: 'screens.users.confirmationDialog.message'
    },
    searchPlaceholder: {
        id: 'screens.users.input.search.placeholder'
    },
    inviteUser: {
        id: 'screens.users.button.invuteUser'
    }
})

const hoverStyle = () => {
    return css`
    &:hover {
      cursor: pointer;
    }
  `
}

const useStyles = makeStyles(theme => ({
    tableTopHeader: {
        position: 'sticky',
        top: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        zIndex: 1,
        backgroundColor: '#f2f5f7'
    },
    tableTopActionButtons: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3)
    },
    leftActionButtons: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-start'
    },
    rightActionButtons: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    dialogTitle: {
        fontWeight: 'bold',
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1)
    },
    dialogContent: {
        maxWidth: 378,
        paddingBottom: theme.spacing(3),
        paddingRight: theme.spacing(3),
        backgroundColor: theme.palette.background.paper
    }
}))

const Users = props => {
    const tenantId = useTypedSelector(state => state['auth'].user?.tenantId)

    const rowsPerPage = useTypedSelector(state => state['users'].rowsPerPage)

    const currentUserRoles = useTypedSelector(state => state['auth'].user?.roles)

    const users = useTypedSelector(state => state['users'].users)
    const isLoadingUsers = useTypedSelector(state => state['users'].loadingUsers)
    const dispatch = useDispatch()
    const intl = useIntl()
    const [searchText, setSearchText] = useState<string>('')
    const classes = useStyles(props)
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [openUserEdit, setOpenUserEdit] = useState(false)
    const [inviteUserDialogVisible, setInviteUserDialogVisible] = useState(false)
    const [selectedRows, setSelectedRows] = useState<ITenantUser[]>([])
    const [sortOrder, setSortOrder] = useState<string[]>(['asc'])
    const [sortOrderBy, setSortOrderBy] = useState<string[]>(['name'])
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(
        false
    )
    const [itemsToBeDeleted, setItemsToBeDeleted] = useState<ITenantUser[]>([])
    const [tableActions, setTableActions] = useState<Record<string, unknown>[]>([]);
    const [
        currentEditingUser,
        setCurrentEditingUser
    ] = useState<ITenantUser | null>(null)

    useEffect(() => {
        if (tenantId) {
            dispatch(loadUsers(tenantId))
        }
    }, [dispatch, tenantId])

    useEffect(() => {
        const options = [
            {
                name: 'Edit',
                callBack: onColumnClick,
                render: Renders.renderEdit,
                disableGutters: true
            },
        ]

        if (currentUserRoles?.includes('UserManager')) {
            options.push({
                name: 'Delete',
                callBack: onDelete,
                render: Renders.renderDelete,
                disableGutters: true
            })
        }

        setTableActions(options)
    }, [currentUserRoles])

    const [topOffset, setTopOffset] = useState(0)
    const measuredRef = useCallback(node => {
        if (node !== null) {
            setTopOffset(node.getBoundingClientRect().height + 8)
        }
    }, [])

    const onRefresh = () => {
        if (tenantId) {
            dispatch(loadUsers(tenantId))
        }
    }

    const onDelete = item => {
        setItemsToBeDeleted([item])
        setOpenDeleteConfirmation(true)
    }

    const onDeleteItems = () => {
        setItemsToBeDeleted(selectedRows)
        setOpenDeleteConfirmation(true)
    }

    const onColumnClick = (column: ITenantUser) => {
        setCurrentEditingUser(column)
        setOpenUserEdit(true)
    }

    const onHandleRequestSort = (orderBy: string, order: string) => {
        setSortOrder([order])
        setSortOrderBy([orderBy])
    }

    const sortedUsers: ITenantUser[] = useMemo(() => {
        const filteredUsers =
            searchText.length === 0
                ? users
                : users.filter(user => {
                    return (
                        user.name.toLowerCase().includes(searchText.toLowerCase()) ||
                        user.email.toLowerCase().includes(searchText.toLowerCase())
                    )
                })

        return filteredUsers
    }, [users, searchText])

    const onOkDeleteUsers = () => {
        if (tenantId) {
            if (itemsToBeDeleted.length > 0) {
                dispatch(deleteUsers(itemsToBeDeleted, tenantId))
            }
        }
        setSelectedRows([])
        setOpenDeleteConfirmation(false)
    }

    const onCancelDeleteUsers = () => {
        setOpenDeleteConfirmation(false)
    }

    const longTextRender = value => <LongText longText={value || ''} />

    const projectNameRender = value => <LongName longName={value} />

    const accessLevelRender = value => {
        switch (value) {
            case 'Owner':
                return (
                    <MikeTooltip
                        title={intl.formatMessage({ id: `common.roles.${value}` })}
                    >
                        <UserOwner />
                    </MikeTooltip>
                )
            case 'Contributor':
                return <UserContributor />
            default:
                return <UserReader />
        }
    }

    const handleTablePageChange = page => {
        setCurrentPage(page)
    }

    const onChangeRowsPerPage = nRows => {
        if (tenantId) {
            dispatch(setUsersRowsPerPage({ rowsPerPage: nRows }))
            dispatch(loadUsers(tenantId))
        }
    }

    return (
        <>
            <UsersNav />
            <ConfirmationDialog
                open={openDeleteConfirmation}
                title={intl.formatMessage(localMessages.confirmationDialogTitle, {
                    itemCount: itemsToBeDeleted.length
                })}
                message={intl.formatMessage(localMessages.confirmationDialogMessage, {
                    itemCount: itemsToBeDeleted.length
                })}
                ok={intl.formatMessage(messages.yesDelete)}
                onOk={onOkDeleteUsers}
                onCancel={onCancelDeleteUsers}
            />
            <div className={classes.tableTopHeader} ref={measuredRef}>
                <Typography variant={'h4'}>
                    {intl.formatMessage(localMessages.title)}
                </Typography>
                <div className={classes.tableTopActionButtons}>
                    <div className={classes.leftActionButtons}>
                        <SearchInput
                            id="api-access-search-input"
                            placeholder={intl.formatMessage(localMessages.searchPlaceholder)}
                            autoFocus
                            text={searchText}
                            onTextChange={setSearchText}
                        />

                        <RefreshButton onClick={onRefresh} />

                        {selectedRows.length > 0 && (
                            <DeleteButton onClick={onDeleteItems} />
                        )}
                    </div>
                    <div className={classes.rightActionButtons}>
                        <IconButton
                            messageId="screens.users.inviteUser"
                            onClick={() => {
                                setInviteUserDialogVisible(true)
                            }}
                        />
                    </div>
                </div>
            </div>

            <DataTable
                actions={tableActions}
                loading={isLoadingUsers}
                columns={[
                    {
                        field: 'name',
                        label: intl.formatMessage(messages.name),
                        render: projectNameRender,
                        className: hoverStyle
                    },
                    {
                        field: 'email',
                        label: intl.formatMessage(messages.email),
                        render: longTextRender,
                        className: hoverStyle
                    },
                    {
                        field: 'role',
                        label: intl.formatMessage(messages.accessLevel),
                        render: accessLevelRender,
                        className: hoverStyle
                    }
                ]}
                idField={'id'}
                data={sortedUsers}
                onColumnClick={onColumnClick}
                onHandleRequestSort={onHandleRequestSort}
                onSelectionChange={setSelectedRows}
                selectedRows={selectedRows}
                topOffset={topOffset}
                page={currentPage}
                onChangePage={handleTablePageChange}
                rowsPerPage={rowsPerPage}
                _order={sortOrder}
                _orderBy={sortOrderBy}
                onChangeRowsPerPage={onChangeRowsPerPage}
            />

            <MikeSlidingPanel
                position="right"
                isOpen={openUserEdit}
                onClose={() => {
                    setOpenUserEdit(false)
                }}
                titleArea={intl.formatMessage({
                    id: 'screens.users.editUser'
                })}
                contentArea={
                    currentEditingUser && (
                        <UserEditForm
                            user={currentEditingUser}
                            handleSubmit={(user: ITenantUser) => {
                                setOpenUserEdit(false)
                                tenantId && dispatch(updateUser(user, tenantId))
                            }}
                        />
                    )
                }
                actionsArea={null}
                noGrayOverlay={false}
            />
            <MikeSlidingPanel
                position="right"
                isOpen={inviteUserDialogVisible}
                onClose={() => {
                    setInviteUserDialogVisible(false)
                }}
                titleArea={intl.formatMessage({
                    id: 'screens.users.inviteUser'
                })}
                contentArea={
                    <UserInviteForm
                        handleSubmit={(data: UserInviteType) => {
                            setInviteUserDialogVisible(false)
                            tenantId &&
                                dispatch(inviteUser(data.email, data.role, data.url, tenantId))
                        }}
                    />
                }
                actionsArea={null}
                noGrayOverlay={false}
            />
        </>
    )
}

export default Users
