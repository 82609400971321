import React from 'react'

import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { IntlProvider, createIntl } from 'react-intl'
import { Provider } from 'react-redux'
import { MuiThemeProvider } from '@material-ui/core/styles'
import appReducer from './reducers'
import rootSaga from './sagas'
import bootstrapSaga from './sagas/bootstrap'
import initApis from './apis'
import ConnectedApp from './containers/ConnectedApp'
import messages from './intl/messages/en.json'
import { theme } from './styles/theme'
import { OidcProvider, useOidc } from '@axa-fr/react-oidc'
import { OidcConfiguration } from '@axa-fr/react-oidc/dist/vanilla/oidc'
import OpenIdStatusPage from './components/LandingPage/OpenIdStatusPage'
import { defineMessages } from 'react-intl'
import fetchConfig from './apis/config'
import InvitationProvider from './components/InvitationProvider'
import { BrowserRouter as Router } from 'react-router-dom'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { setupMatomo } from './helpers/matomo'


const muiLicenseKey: string = process.env.REACT_APP_MUI_LICENSE_KEY
LicenseInfo.setLicenseKey(muiLicenseKey)

const sagaMiddleware = createSagaMiddleware()

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
    appReducer,
    composeEnhancer(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)

// export intl
export const intl = createIntl({ locale: 'en', messages: messages })

const localMessages = defineMessages({
    headline: {
        id: 'screens.openIdStatusPage.headline'
    },
    pleaseWait: {
        id: 'screens.openIdStatusPage.pleaseWait'
    },
    errorHeadline: {
        id: 'screens.openIdStatusPage.errorHeadline'
    },
    errorBody: {
        id: 'screens.openIdStatusPage.errorBody'
    },
    loading: {
        id: 'screens.openIdStatusPage.loading'
    }
})

type IndexPropType = {
    openIdConfig: OidcConfiguration
}

const Index = (props: IndexPropType) => {
    const { login } = useOidc()
    const { openIdConfig } = props

    return (
        <Provider store={store}>
            <IntlProvider locale="en" messages={messages}>
                <OidcProvider
                    configuration={openIdConfig}
                    onSessionLost={() => {
                        login()
                    }}
                    authenticatingComponent={() => (
                        <OpenIdStatusPage
                            headline={intl.formatMessage(localMessages.headline)}
                            body={intl.formatMessage(localMessages.pleaseWait)}
                        />
                    )}
                    callbackSuccessComponent={() => (
                        <OpenIdStatusPage
                            headline={intl.formatMessage(localMessages.headline)}
                            body={intl.formatMessage(localMessages.pleaseWait)}
                        />
                    )}
                    authenticatingErrorComponent={() => (
                        <OpenIdStatusPage
                            headline={intl.formatMessage(localMessages.errorHeadline)}
                            body={intl.formatMessage(localMessages.errorBody)}
                            showRetryButton={true}
                        />
                    )}
                    loadingComponent={() => (
                        <OpenIdStatusPage
                            headline={intl.formatMessage(localMessages.loading)}
                            body={intl.formatMessage(localMessages.pleaseWait)}
                        />
                    )}
                >
                    <MuiThemeProvider theme={theme}>
                        <InvitationProvider>
                            <Router>
                                <ConnectedApp />
                            </Router>
                        </InvitationProvider>
                    </MuiThemeProvider>
                </OidcProvider>
            </IntlProvider>
        </Provider>
    )
}

fetchConfig().then(config => {
    const openIdConfig = {
        client_id: config.auth.clientName,
        redirect_uri: `${window.location.origin}/authentication/callback`,
        silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`,
        scope: 'openid offline_access',
        authority: config.auth.url,
        service_worker_relative_url: '/OidcServiceWorker.js',
        service_worker_only: false,
        refresh_time_before_token_expiration_in_second: 120
    }

    if (config.environment === 'prod') setupMatomo()

    sagaMiddleware
        .run(bootstrapSaga)
        .toPromise()
        .then(() => {
            initApis(config)
            ReactDOM.render(
                <Index openIdConfig={openIdConfig} />,
                document.getElementById('root')
            )
        })
        // eslint-disable-next-line no-console
        .catch(error => console.error(error))
})
