import { ReportTypeEnum } from './enums'

export const getReportRequestBody = (
  tenantId: string,
  reportType: ReportTypeEnum
): Record<string, unknown> => ({
  tenantId,
  reportType,
  reportName: generateFileName(),
  folder: `Credit Reports\\${generateFolderName()}`
})

const generateFileName = (): string => {
  const today = new Date()
  const hours = String(today.getHours()).padStart(2, '0')
  const minutes = String(today.getMinutes()).padStart(2, '0')
  const seconds = String(today.getSeconds()).padStart(2, '0')

  const formattedTime = `${hours}${minutes}${seconds}`
  return `credit-report-${formattedTime}.csv`
}

export const generateFolderName = (): string => {
  const today = new Date()
  const day = String(today.getDate()).padStart(2, '0')
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const year = today.getFullYear()

  const formattedDate = `${year}${month}${day}`
  return `Credit Reports ${formattedDate}`
}
